import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import SEO from '../components/molecules/seo';
import HeroBanner from '../components/molecules/hero-banner-section';
import CaseStudiesGrid from '../components/organisms/case-studies-grid';

const Trabajos = ({ data }) => (
  <>
    <SEO
      title="Conoce algunos de nuestros últimos casos de éxito | JR Web"
      description="Últimos trabajos realizados en Tenerife. Desde aplicaciones móviles hasta tiendas e-commerce, pasando por gestores deportivos."
    />
    <HeroBanner
      content={{
        title: 'Nuestros trabajos',
        description: ''
      }}
    />
    <CaseStudiesGrid data={data} />
  </>
);

Trabajos.propTypes = {
  data: PropTypes.shape({
    firstResult: PropTypes.object.isRequired,
    secondThirdResults: PropTypes.object.isRequired,
    restOfResults: PropTypes.object.isRequired,
  }).isRequired,
};

export default Trabajos;

export const query = graphql`
  query {
    firstResult: allMdx(
      limit: 1
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          id
          frontmatter {
            slug
            title
            intro
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 478, quality: 90) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            featuredImageAlt
          }
        }
      }
    }
    secondThirdResults: allMdx(
      limit: 2
      skip: 1
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          id
          frontmatter {
            slug
            title
            intro
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 478, quality: 90) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            featuredImageAlt
          }
        }
      }
    }
    restOfResults: allMdx(
      skip: 3
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          id
          frontmatter {
            slug
            title
            intro
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 380, quality: 90) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            featuredImageAlt
          }
        }
      }
    }
  }
`;
