import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import Card from '../molecules/card';

import styles from './case-studies-grid.module.scss';

const CaseStudiesGrid = ({ data }) => {
  const firstResult = data.firstResult.edges;
  const secondThirdResults = data.secondThirdResults.edges;
  const restOfResults = data.restOfResults.edges;

  return (
    <section className={styles.container}>
      <div className={styles.grid}>
        {firstResult.map(({ node }) => (
          <Link
            className={styles.firstCaseStudy}
            to={`/trabajos/${node.frontmatter.slug.replace('/', '')}/`}
            key={node.id}
          >
            <article>
              <Img
                className={styles.image}
                fluid={node.frontmatter.featuredImage.childImageSharp.fluid}
                alt={node.frontmatter.featuredImageAlt}
              />
              <h2 className={styles.title}>{node.frontmatter.title} </h2>
              <p className={styles.excerpt}>{node.frontmatter.intro}</p>
              <p className={styles.readMore}>Seguir leyendo »</p>
            </article>
          </Link>
        ))}

        {secondThirdResults.map(({ node }) => (
          <Link
            className={styles.secondThirdCaseStudies}
            to={`/trabajos/${node.frontmatter.slug.replace('/', '')}/`}
            key={node.id}
          >
            <article>
              <Img
                className={styles.image}
                fluid={node.frontmatter.featuredImage.childImageSharp.fluid}
                alt={node.frontmatter.featuredImageAlt}
              />
              <h2 className={styles.title}>{node.frontmatter.title} </h2>
              <p className={styles.excerpt}>{node.frontmatter.intro}</p>
              <p className={styles.readMore}>Seguir leyendo »</p>
            </article>
          </Link>
        ))}

        {restOfResults.map(({ node }) => (
          <Card
            key={node.id}
            content={{
              slug: `/trabajos/${node.frontmatter.slug.replace('/', '')}/`,
              title: node.frontmatter.title,
              intro: node.frontmatter.intro,
              image: node.frontmatter.featuredImage.childImageSharp.fluid,
              imageAlt: node.frontmatter.featuredImageAlt,
            }}
          />
        ))}
      </div>
    </section>
  );
};

CaseStudiesGrid.propTypes = {
  data: PropTypes.shape({
    firstResult: PropTypes.object.isRequired,
    secondThirdResults: PropTypes.object.isRequired,
    restOfResults: PropTypes.object.isRequired,
  }).isRequired,
};

export default CaseStudiesGrid;
